import {createIamStateMachine, IamStates} from './IamStateMachine'
import {AuthenticationError, IamApi} from './IamApi'
import {createEffect, createSignal, untrack} from 'solid-js'
import {CognitoUser} from '@aws-amplify/auth'
import {asDoc, dump} from '@peachy/utility-kit-pure'
import {SmeUserService} from '@peachy/iam-sme-client'

export type IamService = {

    trySignBackIn(): boolean
    signIn(email: string, password: string): boolean
    signOut(redirectUrl?: string): boolean

    completeNewPassword(newPassword: string): boolean
    cancelNewPassword(): boolean

    // confirmEmailCode(email: string, password: string, confirmationCode: string): boolean
    // requestEmailCode(email: string): boolean
    // cancelEmailCode(): boolean
    //

    requestPasswordReset(): boolean
    cancelPasswordReset(): boolean
    requestPasswordCode(email: string): boolean
    resetPassword(email: string, newPassword: string, passwordCode: string): boolean

    currentState(): IamStates
    isInState(...s: IamStates[]) : boolean
    isSignedIn(): boolean

    isInUserGroups(...groups: string[]): boolean
    userGroups(): string[]
    idToken(): string

    isBusy(): boolean

    error(): AuthenticationError
}


export function createIamService(iamApi: IamApi, smeUserService: SmeUserService): IamService {

    const iamStateMachine = createIamStateMachine()

    const [error, setError] = createSignal<AuthenticationError | undefined>()
    const [user, setUser] = createSignal<CognitoUser>()

    createEffect(() => {
        iamStateMachine.currentState()
        console.log(iamStateMachine.currentState())
        setError()
    })


    const iamService = {
        trySignBackIn: () => iamStateMachine.signBackIn(async () => {
            try {
                const user = await iamApi.currentAuthenticatedUser()
                iamStateMachine.signBackInSuccess()
                setUser(user)
            } catch (e) {
                console.log('error signing in', e)
                iamStateMachine.signBackInFailure()
            }
        }),

        signIn(email: string, password: string): boolean {
            return iamStateMachine.signIn(async () => {
                try {
                    const user: CognitoUser = await iamApi.signIn(email, password)

                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        iamStateMachine.requiresNewPassword()
                    }
                    iamStateMachine.signInSuccess()
                    setUser(user)

                } catch (e) {
                    const error = iamApi.extractError(e)

                    if(error === 'PasswordExpired') {
                        await smeUserService.reInvite(email)
                    }

                    iamStateMachine.signInFailure()
                    setError(error)
                }
            })
        },

        signOut(redirectUrl?: string): boolean {
            return iamStateMachine.signOut(async () => {
                try {
                    await iamApi.signOut()

                    if (redirectUrl) {
                        window.location.href = redirectUrl
                    } else {
                        iamStateMachine.signOutSuccess()
                    }

                } catch (e) {
                    iamStateMachine.signOutFailure()
                    setError(iamApi.extractError(e))
                }
            })
        },

        requestPasswordReset: iamStateMachine.requestPasswordReset,
        cancelPasswordReset: iamStateMachine.cancelPasswordReset,

        requestPasswordCode: (email: string) => iamStateMachine.requestPasswordCode(async () => {
            try {
                const response = await iamApi.forgotPassword(email)
                console.log(response)
                iamStateMachine.requestPasswordCodeSuccess()

            } catch (e) {
                iamStateMachine.requestPasswordCodeFailure()
                setError(iamApi.extractError(e))
            }
        }),

        resetPassword: (email: string, newPassword: string, passwordCode: string) => iamStateMachine.resetPassword(async () => {
            try {
                await iamApi.forgotPasswordSubmit(email, passwordCode, newPassword)
                iamStateMachine.resetPasswordSuccess()
            } catch (e) {
                iamStateMachine.resetPasswordFailure()
                setError(iamApi.extractError(e))
            }
        }),

        completeNewPassword(newPassword: string): boolean {
            return iamStateMachine.completeNewPassword(async () => {
                try {
                    const authedUser = await iamApi.completeNewPassword(untrack(user), newPassword)
                    iamStateMachine.newPasswordSuccess()
                    setUser(authedUser)

                } catch (e) {
                    iamStateMachine.newPasswordFailure()
                    setError(iamApi.extractError(e))
                }
            })
        },

        cancelNewPassword: iamStateMachine.cancelNewPassword,

        isInState(...s: IamStates[]): boolean {
            return iamStateMachine.isInState(...s)
        },

        currentState() {
            return iamStateMachine.currentState()
        },

        isBusy(): boolean {
            return iamStateMachine.hasTag('busy')
        },

        isInUserGroups(...groups: string[]): boolean {
            return this.userGroups()?.some((g: string) => groups.includes(g))
        },

        isSignedIn(): boolean {
            return this.isInState('SignedIn')
        },

        idToken() {
            // @ts-ignore

            return asDoc(user()?.signInUserSession)
        },

        userGroups(): string[] {
            // @ts-ignore
            return user()?.signInUserSession.accessToken.payload['cognito:groups']
        },

        error(): AuthenticationError {
            return error()
        }
    }
    iamService.trySignBackIn()
    return iamService
}
