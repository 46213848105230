import {makeMaker} from './src/mixin/mixin'
import {QuoteLifeRequest, QuoteLifeResponse} from '@peachy/quote-pure'

export * from './src/QuoteServiceClient'
export * from './scripts/policy'

export {QuoteLifeRequest, QuoteLifeResponse}

export * from './src/QuoteStateMachine'


export const constructQuoteRequest = makeMaker(QuoteLifeRequest)

        import * as me from '@peachy/quote-solid-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src3102476736/src/codestar-connections.eu-west-2.amazonaws.com/git-http/011528273016/eu-west-2/ba15a82a-f179-4aff-b6b0-ab0bddfd2d62/peachy-punnet/peachy-mono-repo/comp-kit/quote/solid-client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/quote-solid-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    